import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import "../../index.css";
import bee from "../../assets/images/greenBee.svg";
import Arrow from "../../assets/images/arrow";
import bullet from "../../assets/images/bullet.svg";
import bullet_green from "../../assets/images/bullet_green.svg";
import {
    increment,
    selectProject,
    setLevel,
} from "../../redux/slices/scrollSlice";
import { useDispatch } from "react-redux";
import Lines from "../lines/Lines";
import thinArrow from "../../assets/images/thinArrow.svg";
import WordAnimation from "../wordAnimation/WordAnimation";

const projectNames = [
    "Call me",
    "F12 Performance",
    "Evmet",
    "eco4u",
    "Pinnacle",
];

const Bio = () => {
    const dispatch = useDispatch();
    const bioRef = useRef<HTMLDivElement>(null);
    const [isHovered, setIsHovered] = useState<number | undefined>(undefined);
    const [showArrow, setShowArrow] = useState<boolean>(false);

    function handleSelectInfo(name: string) {
        dispatch(selectProject(name));
        dispatch(setLevel(1));
        let currentPath = window.location.pathname;
        let newPath = currentPath + name.split(" ").join("");

        window.history.pushState(name, "", newPath);
    }

    useEffect(() => {
        window.onload = () => {
            setTimeout(() => {
                setShowArrow(true);
            }, 500);
        };
    }, []);

    return (
        <div className={`${styles.bioSection} ${"bioSection"}`} ref={bioRef}>
            <div className={`${styles.bioContainer} bioContainer`} id="bioContainer">
                <div className={`${styles.bio} ${styles.noBlur}`}>
                    <div className={`${styles.row}`}>
                        <h1 className={`${styles.noBlur} ${styles.name}`}>ALEX BAILEY</h1>{" "}
                        <a
                            href="https://www.linkedin.com/in/alex-bailey-9ba821229/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            LinkedIn
                        </a>
                    </div>
                    <div className={`${styles.row}`}>
                        <h1 className={`${styles.noBlur} ${styles.dev}`}>
                            FULL STACK DEVELOPER
                        </h1>
                        <a
                            href="https://www.github.com/bailey30"
                            target="_blank"
                            rel="noreferrer"
                        >
                            GitHub
                        </a>
                    </div>
                </div>
                <div className={`${styles.works}`}>
                    <div className={styles.row}>
                        <h2 className={`${styles.works}`}>
                            SELECTED WORKS{" "}
                            <div className={styles.arrow}>
                                <img
                                    src={thinArrow}
                                    alt="arrow"
                                    className={`${styles.arrow} ${showArrow && styles.show} ${isHovered !== undefined && styles.hovered
                                        }`}
                                />
                            </div>
                        </h2>
                    </div>
                    {projectNames.map((project, i) => {
                        return (
                            <>
                                <h3
                                    key={i}
                                    onMouseEnter={() => setIsHovered(i)}
                                    onMouseLeave={() => setIsHovered(undefined)}
                                    onClick={() => handleSelectInfo(project)}
                                    className={`${isHovered !== i &&
                                        isHovered !== undefined &&
                                        styles.otherHovered
                                        }`}
                                >
                                    <WordAnimation
                                        isHovered={isHovered === i && true}
                                        word={project}
                                        type="name"
                                    />
                                </h3>

                                {i !== projectNames.length - 1 && (
                                    <span className={styles.slash}>&nbsp;/&nbsp;</span>
                                )}
                            </>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Bio;
