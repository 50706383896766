import evmet from "../../assets/images/evmet.gif";
import pinnacle from "../../assets/images/pinnacle.gif";
import f12 from "../../assets/images/f12.gif";
import eco4u from "../../assets/images/eco4u.gif";
import callme from "../../assets/images/callme.gif";

import evmetVid from "../../assets/videos/evmet.mp4";
import pinnacleVid from "../../assets/videos/pinnacle.mp4";
import f12Vid from "../../assets/videos/f12.mp4";
import eco4uVid from "../../assets/videos/eco4u.mp4";
import callmeVid from "../../assets/videos/callme.mp4";

import evmetMin from "../../assets/images/evmet-min.jpeg";
import pinnacleMin from "../../assets/images/pinnacle-min.jpeg";
import f12Min from "../../assets/images/f12-min.jpeg";
import eco4uMin from "../../assets/images/eco4u-min.jpeg";
import callmeMin from "../../assets/images/callme-min.jpeg";

export type Data = {
  [key: string]: {
    img: string;
    min: string;
    vid: string;
    tools: string[];
    role: string;
    name: string;
    link: string;
    date: string;
  };
};

export const data: Data = {
  Evmet: {
    name: "Evmet",
    img: evmet,
    vid: evmetVid,
    min: evmetMin,
    tools: ["TypeScript", "React", "Styled Components"],
    role: "Front end development",
    link: "https://www.evmetltd.com/",
    date: "OPOP Media, 2022",
  },
  F12Performance: {
    name: "F12 Performance",
    img: f12,
    vid: f12Vid,
    min: f12Min,
    tools: [
      "TypeScript",
      "React",
      "AutoTrader API",
      "Node",
      "SQL",
      "Styled Components",
    ],
    role: "Full stack development",
    link: "https://www.f12performance.co.uk/",
    date: "OPOP Media, 2022",
  },
  Callme: {
    img: callme,
    vid: callmeVid,
    min: callmeMin,
    name: "Call me",
    tools: [
      "TypeScript",
      "React",
      "Redux",
      "SCSS",
      "Stripe",
      "amazon-cognito-identity-js",
    ],
    role: "Front end development",
    link: "https://call-me.vip/",
    date: "OPOP Media, 2023",
  },
  eco4u: {
    img: eco4u,
    vid: eco4uVid,
    min: eco4uMin,
    name: "eco4u",
    tools: ["TypeScript", "React", "Redux", "Node", "SQL", "Styled Components"],
    role: "Full stack development",
    link: "https://eco4-u.co.uk",
    date: "OPOP Media, 2022",
  },
  Pinnacle: {
    img: pinnacle,
    vid: pinnacleVid,
    min: pinnacleMin,
    name: "Pinnacle",
    tools: ["Typescript", "React", "Redux", "Styled Components"],
    role: "Front end development",
    link: "https://pinnacle-lifestyle.com/",
    date: "OPOP Media, 2022",
  },
};
